import { select, put, call, all, takeLatest } from 'redux-saga/effects';
import { DAEMON } from '@dbh/redux-extra';
import { makeSelectLocale, makeSelectCountry, COUNTRY_ANDOR_LOCALE_CHANGED } from '@dbh/routing-redux';
import { SSR_STARTED } from '@dbh/ssr-data-redux';
import Sentry from '@dbh/sentry';
import { API_IDS } from '@dbh/api-constants';
import { makeSelectApiUrl } from '@dbh/configuration-redux';
import { request } from '@dbh/request-queue-redux';
import { makeSelectSagaRequestOptions } from '@dbh/saga-request-options';
import { ApiRequestError } from '@dbh/request-errors';
import { makeSelectMessagesLoaded, messagesLoaded, messagesLoadingError, REDUX_AND_SAGA_KEY } from './index.js';
import 'prop-types';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';
import '@babel/runtime/helpers/esm/jsx';
import 'react';
import '@dbh/reselect-extra';
import 'styled-components';
import 'react-intl';
import '@dbh/environment';
import '@dbh/spinner';
import '@dbh/hydration';
import 'immutable';
import 'react-immutable-proptypes';
import 'lodash/startsWith';
import 'reselect';
import '@dbh/development-redux';
import '@dbh/lodash-extra';
import 'lodash/noop';
import '@dbh/create-custom-react-prop-type';
import '@dbh/react-extra';

/**
 * Turn this: `[{ code: String, culture: String, message: String }, ...]`
 * to this: `{ code: message, anotherCode: anotherMessage }`.
 * @param {Array} apiMessageObject .
 * @return {Object} .
 */const apiMessageObjectToReactIntlMessageObject=withConformsTo("apiMessageObjectToReactIntlMessageObject",[],a=>a.reduce((a,b)=>{const c={...a},{code:d,message:e}=b;return c[d]=e,c},{}));var apiMessageObjectToReactIntlMessageObject$1 = apiMessageObjectToReactIntlMessageObject;

function*fetchTranslationMessages(a){try{const{payload:b}=a;let c=b&&b.country,d=b&&b.locale;d||(d=yield select(makeSelectLocale())),c||(c=yield select(makeSelectCountry()));// Check cache.
const e=yield select(makeSelectMessagesLoaded(d));if(e)return void(yield put(messagesLoaded({locale:d,fromCache:!0})));const f=yield select(makeSelectSagaRequestOptions(API_IDS.messages)),g=yield select(makeSelectApiUrl(API_IDS.messages)),h=yield call(request,g,f),{emptyResponse:i,apiResponse:j,requestAttributes:k}=h;// API request.
if(i)throw new ApiRequestError(h);let l=j["hydra:member"];l=apiMessageObjectToReactIntlMessageObject$1(l),yield put(messagesLoaded({locale:d,country:c,messages:l,requestAttributes:k}));}catch(a){Sentry.captureException(a),yield put(messagesLoadingError(a));}}

function*rootSagaMessages(){yield all([takeLatest(COUNTRY_ANDOR_LOCALE_CHANGED,fetchTranslationMessages),// Note: `LOCATION_CHANGE` is fired before `SSR_STARTED` in SSR, but the
// SAGAs don't pick up any event before `SSR_STARTED`. Also, we use
// `takeLatest` even though there won't (correctly) be a second `SSR_STARTED`
// event, since `take` doesn't work here.
takeLatest(SSR_STARTED,fetchTranslationMessages)]);}const injectedSagaConfiguration={key:REDUX_AND_SAGA_KEY,mode:DAEMON};

export { rootSagaMessages as default, injectedSagaConfiguration };
